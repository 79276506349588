// src/App.js
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Navbar from './components/Navbar';

// Existing imports for your other components
import Home from './components/Home';
import Login from './components/Login';
import ExitVelocityTracker from './components/ExitVelocityTracker';
import Settings from './components/Settings';
import WhosHot from './components/WhosHot';
import StartingPitcherStats from './components/StartingPitcherStats';
import DailyLineupStats from './components/DailyLineupStats';
import Scores from './components/Scores';
import ArbitrageCalculator from './components/ArbitrageCalculator';
import OddsConverter from './components/OddsConverter';
import MlbOdds from './components/mlbodds';
import ParkFactors from './components/ParkFactors';
import MLBDefinitions from './components/MLBDefinitions';
import InningFirstStats from './components/InningFirstStats';
import TopHRPredictions from './components/TopHRPredictions';
import DefensiveStats from './components/DefensiveNFLStats';
import TopTDPredictions from './components/TopTDPredictions';
import TopTDLongshots from './components/TopTDLongshots';
import CoverageMatrix from './components/CoverageMatrix';
import NflHitRates from './components/NFLHitRates';
import WhosHotNBA from './components/WhosHotNBA';
import FAQ from './components/FAQ';
import NBAPlayerStatsTracker from './components/NBAPlayerStatsTracker';
import AwardsPage from './components/AwardsPage';
import NbaThreePointerHub from './components/NbaThreePointerHub';

// ***** NEW COMPONENT for NBA first-basket predictions *****
// Adjust the import path to match your actual file/component name
import NBAFirstBasketPredictions from './components/NBAFirstBasketPredictions';

import './App.css';

function App() {
  const [favoriteTeam, setFavoriteTeam] = useState(
    localStorage.getItem('favoriteTeam') || 'none'
  );
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Dark Mode State
  const [darkMode, setDarkMode] = useState(
    () => JSON.parse(localStorage.getItem('darkMode')) || false
  );

  // Update CSS variables when darkMode or favoriteTeam changes
  useEffect(() => {
    const team = {}; // If you have logic for a specific team, integrate it here
    const defaultColor = '#000080';
    document.documentElement.style.setProperty(
      '--background-color',
      darkMode ? '#121212' : '#F2F2F2'
    );
    document.documentElement.style.setProperty(
      '--text-color',
      darkMode ? '#FFFFFF' : team.color || defaultColor
    );
    document.documentElement.style.setProperty(
      '--primary-color',
      darkMode ? '#1E1E1E' : '#1B6484'
    );
    document.documentElement.style.setProperty(
      '--secondary-color',
      darkMode ? '#AED7EC' : '#aed7ec'
    );
    document.documentElement.style.setProperty(
      '--accent-color',
      '#C8102E' // Same in both modes for now
    );
    document.documentElement.style.setProperty(
      '--hover-color',
      darkMode ? '#333333' : '#0e4a6e'
    );
    document.documentElement.style.setProperty('--white-color', '#FFFFFF');
    document.documentElement.style.setProperty('--black-color', '#000000');

    // Save dark mode preference
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode, favoriteTeam]);

  // Check auth status on mount
  useEffect(() => {
    fetch('/api/auth/status')
      .then((response) => response.json())
      .then((data) => {
        setIsAuthenticated(data.isAuthenticated);
      })
      .catch((error) => console.error('Error fetching auth status:', error));
  }, []);

  const handleComingSoonClick = (e) => {
    e.preventDefault();
    alert('This feature is coming soon!');
  };

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <Router>
      <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>
        <Navbar
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          handleComingSoonClick={handleComingSoonClick}
        />
        <div className="app-content">
          <Routes>
            {/* HOME */}
            <Route path="/" element={<Home />} />

            {/* MLB Routes */}
            <Route path="/mlb/exit-velocity-tracker" element={<ExitVelocityTracker />} />
            <Route path="/mlb/whos-hot" element={<WhosHot />} />
            <Route path="/mlb/starting-pitcher-stats" element={<StartingPitcherStats />} />
            <Route path="/mlb/daily-lineup-stats" element={<DailyLineupStats />} />
            <Route path="/mlb/scores" element={<Scores />} />
            <Route path="/mlb/SECRETODDS" element={<MlbOdds />} />
            <Route path="/mlb/definition" element={<MLBDefinitions />} />
            <Route path="/mlb/park-factors" element={<ParkFactors />} />
            <Route path="/mlb/inning-first-stats" element={<InningFirstStats />} />
            <Route path="/mlb/the-script" element={<TopHRPredictions />} />

            {/* NBA Routes */}
            <Route path="/nba/stat-tracker" element={<NBAPlayerStatsTracker />} />
            <Route path="/nba/whos-hot" element={<WhosHotNBA />} />
            <Route path="/nba/awards" element={<AwardsPage />} />
            <Route path="/nba/three-pointer-hub" element={<NbaThreePointerHub />} />

            {/* ***** NEW ROUTE for your first-basket predictions ***** */}
            <Route path="/nba/first-basket" element={<NBAFirstBasketPredictions />} />

            {/* Default redirect for /nba to /nba/awards (like you had before) */}
            <Route path="/nba" element={<Navigate to="/nba/awards" replace />} />

            {/* Sportsbooks Routes */}
            <Route
              path="/sportsbooks/arbitrage-calculator"
              element={<ArbitrageCalculator />}
            />
            <Route path="/sportsbooks/odds-converter" element={<OddsConverter />} />

            {/* NFL Routes */}
            <Route path="/nfl/top-td-predictions" element={<TopTDPredictions />} />
            <Route path="/nfl/top-td-longshots" element={<TopTDLongshots />} />
            <Route path="/nfl/defensive-team-stats" element={<DefensiveStats />} />
            <Route
              path="/nfl/target-shares-and-defensive-coverages"
              element={<CoverageMatrix />}
            />
            <Route path="/nfl/nfl-hit-rates" element={<NflHitRates />} />

            {/* Other Routes */}
            <Route path="/faq" element={<FAQ />} />
            <Route path="/login" element={<Login />} />
            {/* Optionally: <Route path="/settings" element={<Settings />} /> */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
