import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import './NbaThreePointerHub.css'; // Ensure CSS file is created and referenced properly

// Placeholder chart components - integrate a real chart library as needed
const BarChart = ({ data }) => <div style={{background:'#eee', padding:'1rem', borderRadius:'8px'}}>Bar Chart Placeholder</div>;
const LineChart = ({ data }) => <div style={{background:'#eee', padding:'1rem', borderRadius:'8px'}}>Line Chart Placeholder</div>;

// PlayerCard Component
const PlayerCard = ({ player }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => setShowDetails((prev) => !prev);

  return (
    <div className="player-card">
      <div className="player-summary">
        <img src={player.profile_picture || player.picture} alt={player.name} />
        <div>
          <h4>{player.name}</h4>
          <p>3P% (Season): {(player.season_3p_pct * 100).toFixed(1)}%</p>
          <p>Last 5 Games Avg 3PA: {(player.last5_three_pa_avg || player.season_avg_3pa).toFixed(1)}</p>
          <p>Last 5 Games Avg 3PM: {(player.last5_three_pm_avg || player.season_avg_3pm).toFixed(1)}</p>
        </div>
      </div>
      <button onClick={toggleDetails}>{showDetails ? 'Hide Details' : 'View Details'}</button>
      {showDetails && (
        <div className="player-details">
          <p>Season Avg 3PM: {player.season_avg_3pm.toFixed(1)}</p>
          <p>Season Avg 3PA: {player.season_avg_3pa.toFixed(1)}</p>
          <p>3P%: {(player.season_3p_pct * 100).toFixed(1)}%</p>
          <p>Δ 3PM (L7 - Season): {player.delta_3pm >= 0 ? `+${player.delta_3pm.toFixed(1)}` : player.delta_3pm.toFixed(1)}</p>
          <p>Prop Line (FanDuel): Over {player.point} {player.odds}</p>
          <div className="charts">
            <BarChart data={player.chartsData?.fgmOverTime} />
            <LineChart data={player.chartsData?.oddsTrends} />
          </div>
        </div>
      )}
    </div>
  );
};

// TeamRoster Component
const TeamRoster = ({ teamData, players }) => {
  return (
    <div className="team-roster">
      <h3 style={{ backgroundColor: teamData.primaryColor || '#333' }}>{teamData.name}</h3>
      <div className="player-list">
        {players.map((player) => (
          <PlayerCard key={player.name} player={player} />
        ))}
      </div>
    </div>
  );
};

// GameCard Component
const GameCard = ({ gameId, homeTeam, awayTeam, gameTime, playerData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const homePlayers = playerData.filter((p) => p.team === homeTeam.abbrev);
  const awayPlayers = playerData.filter((p) => p.team === awayTeam.abbrev);

  return (
    <div className="game-card">
      <div className="game-header" onClick={toggleExpand}>
        <div className="team-info">
          <img src={homeTeam.logo} alt={`${homeTeam.name} logo`} />
          <span>{homeTeam.name}</span>
        </div>
        <div className="game-time">{gameTime}</div>
        <div className="team-info">
          <span>{awayTeam.name}</span>
          <img src={awayTeam.logo} alt={`${awayTeam.name} logo`} />
        </div>
      </div>
      {isExpanded && (
        <div className="game-details">
          <TeamRoster teamData={homeTeam} players={homePlayers} />
          <TeamRoster teamData={awayTeam} players={awayPlayers} />
        </div>
      )}
    </div>
  );
};

// Main NbaThreePointerHub Component
const NbaThreePointerHub = () => {
  const [games, setGames] = useState([]);
  const [playerData, setPlayerData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        const dateStr = `${yyyy}${mm}${dd}`;

        // Fetch scoreboard
        const scoreboardUrl = `https://site.api.espn.com/apis/site/v2/sports/basketball/nba/scoreboard?dates=${dateStr}`;
        const scoreboardResponse = await axios.get(scoreboardUrl);
        const events = scoreboardResponse.data.events || [];

        const parsedGames = events.map((event) => {
          const competition = event.competitions[0];
          const home = competition.competitors.find(c => c.homeAway === 'home');
          const away = competition.competitors.find(c => c.homeAway === 'away');
          return {
            id: event.id,
            date: event.date,
            homeTeam: {
              name: home.team.displayName,
              abbrev: home.team.abbreviation,
              logo: home.team.logo,
              primaryColor: '#1b6484' // adjust as needed
            },
            awayTeam: {
              name: away.team.displayName,
              abbrev: away.team.abbreviation,
              logo: away.team.logo,
              primaryColor: '#1b6484' // adjust as needed
            },
            gameTime: new Date(event.date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
          };
        });
        setGames(parsedGames);

        // Fetch player odds for over threes
        const oddsResponse = await axios.get('/api/nba-player-prop-odds/', {
          params: {
            market: 'player_threes',
            outcome: 'Over',
            sort: 'name',
            direction: 'asc',
            sportsbook: 'FanDuel'
          }
        });
        const playerOdds = oddsResponse.data.results || [];

        const playerNames = playerOdds.map(p => p.name);

        // Instead of fetching all players at once, fetch each player individually
        async function fetchThreeStats(playerName) {
          const res = await axios.get('/api/nba-gen-player-stats/three-stats/', {
            params: { player_name: playerName }
          });
          // Assume the endpoint returns an array of one object for the player
          // If it returns just one object, adjust accordingly
          const data = Array.isArray(res.data) ? res.data[0] : res.data;
          return { [playerName.toLowerCase()]: data };
        }

        // Fetch all player stats individually
        const playerStatsResults = await Promise.all(playerNames.map(name => fetchThreeStats(name)));
        // Merge all results into one map
        const threeStatsMap = {};
        playerStatsResults.forEach(obj => {
          const key = Object.keys(obj)[0]; 
          threeStatsMap[key] = obj[key];
        });

        // Merge player odds and three-stats data
        const combinedPlayerData = playerOdds.map(p => {
          const stats = threeStatsMap[p.name.toLowerCase()] || {};
          return {
            ...p,
            name: p.name,
            team: stats.team || '',
            profile_picture: stats.profile_picture || '',
            season_avg_3pm: stats.season_avg_3pm || 0,
            season_avg_3pa: stats.season_avg_3pa || 0,
            season_3p_pct: stats.season_3p_pct || 0,
            delta_3pm: stats.delta_3pm || 0
          };
        });

        setPlayerData(combinedPlayerData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ padding: '1rem' }}>
      <h1>NBA Three Pointer Hub</h1>
      {games.map((game) => (
        <GameCard
          key={game.id}
          gameId={game.id}
          homeTeam={game.homeTeam}
          awayTeam={game.awayTeam}
          gameTime={game.gameTime}
          playerData={playerData}
        />
      ))}
    </div>
  );
};

export default NbaThreePointerHub;
