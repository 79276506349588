// src/components/NBAFirstBasketPredictions.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import FlippableCard from "./FlippableCard";
import "./NBAFirstBasketPredictions.css";

export default function NBAFirstBasketPredictions() {
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Paywall tracking
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // 1) On mount, check subscription (like your NBAPlayerStatsTracker.js)
  useEffect(() => {
    axios
      .get("/profile/")
      .then((response) => {
        const { access_token } = response.data || {};
        return checkSubscriptionStatus(access_token);
      })
      .then((isSubscribed) => {
        if (isSubscribed) {
          setIsAuthenticated(true);
        } else {
          window.location.href = "https://dubclub.win/r/Zeus_Analytics/";
        }
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
        // If unauthorized or error => redirect to login
        window.location.href = "https://zeusanalytics.org/accounts/dubclub/login/";
      });
  }, []);

  // 2) Once isAuthenticated === true, fetch the predictions
  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      axios
        .get("/api/nba-multi-factor-first-basket/?limit=8")
        .then((response) => {
          // Each item should have a .profile_picture from the backend
          setPredictions(response.data || []);
        })
        .catch((err) => {
          console.error("Error fetching first basket predictions:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isAuthenticated]);

  // 3) Check subscription API call
  async function checkSubscriptionStatus(token) {
    try {
      const response = await axios.get("/api/check-subscription/", {
        params: { token },
      });
      const subscriptions = response.data;
      const currentTime = new Date().toISOString();

      // Adjust if your "capper.id" or logic differs
      const isSubscribed = subscriptions.some(
        (sub) =>
          sub.price.product.capper.id === 320906 &&
          sub.paid_until > currentTime
      );
      return isSubscribed;
    } catch (error) {
      console.error("Error checking subscription:", error);
      return false;
    }
  }

  // 4) Render
  if (isAuthenticated === null) {
    // Means we are still verifying subscription
    return (
      <div className="fbp-loading">
        Checking subscription...
      </div>
    );
  }

  if (loading) {
    return (
      <div className="fbp-loading">
        Loading first basket predictions...
      </div>
    );
  }

  return (
    <div className="fbp-container">
      <h1>NBA First Basket Predictions</h1>
      <div className="fbp-grid">
        {predictions.map((pred) => (
          <div key={pred.id} className="fbp-card-wrapper">
            <FlippableCard
              // teams
              teams={{
                team1: pred.away_team,
                team2: pred.home_team,
              }}
              // partial name from backend
              playerName={pred.predicted_player_name}
              // rely on the backend for profile_picture
              playerImage={pred.profile_picture}
              aiScore={pred.ai_score?.toFixed(2) || "N/A"}
              gameTime="7:30PM ET"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
