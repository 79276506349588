// src/components/Home.js
import React from 'react';

// NBA Logos
import nbaLogo from '../assets/nba/nba.png';
import atlantaHawks from '../assets/nba/Atlanta_Hawks.png';
import bostonCeltics from '../assets/nba/Boston_Celtics.png';
import brooklynNets from '../assets/nba/Brooklyn_Nets.png';
import charlotteHornets from '../assets/nba/Charlotte_Hornets.png';
import chicagoBulls from '../assets/nba/Chicago_Bulls.png';
import clevelandCavaliers from '../assets/nba/Cleveland_Cavaliers.png';
import dallasMavericks from '../assets/nba/Dallas_Mavericks.png';
import denverNuggets from '../assets/nba/Denver_Nuggets.png';
import detroitPistons from '../assets/nba/Detroit_Pistons.png';
import goldenStateWarriors from '../assets/nba/Golden_State_Warriors.png';
import houstonRockets from '../assets/nba/Houston_Rockets.png';
import indianaPacers from '../assets/nba/Indiana_Pacers.png';
import laClippers from '../assets/nba/La_Clippers.png';
import laLakers from '../assets/nba/La_Lakers.png';
import memphisGrizzlies from '../assets/nba/Memphis_Grizzlies.png';
import miamiHeat from '../assets/nba/Miami_Heat.png';
import milwaukeeBucks from '../assets/nba/Milwaukee_Bucks.png';
import minnesotaTimberwolves from '../assets/nba/Minnesota_Timberwolves.png';
import noPelicans from '../assets/nba/NO_Pelicans.png';
import newYorkKnicks from '../assets/nba/New_York_Knicks.png';
import oklahomaCityThunder from '../assets/nba/Oklahoma_City_Thunder.png';
import orlandoMagic from '../assets/nba/Orlando_Magic.png';
import philadelphia76ers from '../assets/nba/Philadelphia_76ers.png';
import phoenixSuns from '../assets/nba/Phoenix_Suns.png';
import portlandTrailblazers from '../assets/nba/Portland_Trailblazers.png';
import sacramentoKings from '../assets/nba/Sacramento_Kings.png';
import sanAntonioSpurs from '../assets/nba/San_Antonio_Spurs.png';
import torontoRaptors from '../assets/nba/Toronto_Raptors.png';
import utahJazz from '../assets/nba/Utah_Jazz.png';
import washingtonWizards from '../assets/nba/Washington_Wizards.png';

// MLB Logos
import mlbLogo from '../assets/mlb/MLB.png';
import arizonaDiamondbacks from '../assets/mlb/arizona_diamondbacks.png';
import atlantaBraves from '../assets/mlb/atlanta_braves.png';
import baltimoreOrioles from '../assets/mlb/baltimore_orioles.png';
import bostonRedSox from '../assets/mlb/boston_red_sox.png';
import chicagoCubs from '../assets/mlb/chicago_cubs.png';
import chicagoWhiteSox from '../assets/mlb/chicago_white_sox.png';
import cincinnatiReds from '../assets/mlb/cincinnati_reds.png';
import clevelandGuardians from '../assets/mlb/cleveland_guardians.png';
import coloradoRockies from '../assets/mlb/colorado_rockies.png';
import detroitTigers from '../assets/mlb/detroit_tigers.png';
import houstonAstros from '../assets/mlb/Houston_Astros.png';
import kansasCityRoyals from '../assets/mlb/kansas_city_royals.png';
import losAngelesAngels from '../assets/mlb/los_angeles_angels.png';
import losAngelesDodgers from '../assets/mlb/los_angeles_dodgers.png';
import miamiMarlins from '../assets/mlb/miami_marlins.png';
import milwaukeeBrewers from '../assets/mlb/milwaukee_brewers.png';
import minnesotaTwins from '../assets/mlb/minnesota_twins.png';
import newYorkMets from '../assets/mlb/new_york_mets.png';
import newYorkYankees from '../assets/mlb/new_york_yankees.png';
import oaklandAthletics from '../assets/mlb/oakland_athletics.png';
import philadelphiaPhillies from '../assets/mlb/Philadelphia_Phillies.png';
import pittsburghPirates from '../assets/mlb/pittsburgh_pirates.png';
import sanDiegoPadres from '../assets/mlb/san_diego_padres.png';
import sanFranciscoGiants from '../assets/mlb/san_francisco_giants.png';
import seattleMariners from '../assets/mlb/seattle_mariners.png';
import stLouisCardinals from '../assets/mlb/st_louis_cardinals.png';
import tampaBayRays from '../assets/mlb/tampa_bay_rays.png';
import texasRangers from '../assets/mlb/texas_rangers.png';
import torontoBlueJays from '../assets/mlb/toronto_blue_jays.png';
import washingtonNationals from '../assets/mlb/Washington_Nationals.png';

// NFL Logos
import nflLogo from '../assets/nfl/NFL.png';
import arizonaCardinals from '../assets/nfl/nfl-arizona-cardinals-team-logo-2-150x150.png';
import atlantaFalcons from '../assets/nfl/nfl-atlanta-falcons-team-logo-2-150x150.png';
import baltimoreRavens from '../assets/nfl/nfl-baltimore-ravens-team-logo-2-150x150.png';
import buffaloBills from '../assets/nfl/nfl-buffalo-bills-team-logo-2-150x150.png';
import carolinaPanthers from '../assets/nfl/nfl-carolina-panthers-team-logo-2-150x150.png';
import chicagoBearsNFL from '../assets/nfl/nfl-chicago-bears-team-logo-2-150x150.png';
import cincinnatiBengals from '../assets/nfl/nfl-cincinnati-bengals-team-logo-150x150.png';
import clevelandBrowns from '../assets/nfl/nfl-cleveland-browns-team-logo-2-150x150.png';
import dallasCowboys from '../assets/nfl/nfl-dallas-cowboys-team-logo-2-150x150.png';
import denverBroncos from '../assets/nfl/nfl-denver-broncos-team-logo-2-150x150.png';
import detroitLions from '../assets/nfl/nfl-detroit-lions-team-logo-2-150x150.png';
import greenBayPackers from '../assets/nfl/nfl-green-bay-packers-team-logo-2-150x150.png';
import houstonTexans from '../assets/nfl/nfl-houston-texans-team-logo-2-150x150.png';
import indianapolisColts from '../assets/nfl/nfl-indianapolis-colts-team-logo-2-150x150.png';
import jacksonvilleJaguars from '../assets/nfl/nfl-jacksonville-jaguars-team-logo-2-150x150.png';
import kansasCityChiefs from '../assets/nfl/nfl-kansas-city-chiefs-team-logo-2-150x150.png';
import losAngelesRams from '../assets/nfl/los-angeles-rams-2020-logo-300x300.png';
import losAngelesChargers from '../assets/nfl/nfl-los-angeles-chargers-team-logo-2-150x150.png';
import miamiDolphins from '../assets/nfl/nfl-miami-dolphins-logo-2018-150x150.png';
import minnesotaVikings from '../assets/nfl/nfl-minnesota-vikings-team-logo-2-150x150.png';
import newEnglandPatriots from '../assets/nfl/nfl-new-england-patriots-team-logo-2-150x150.png';
import newOrleansSaints from '../assets/nfl/nfl-new-orleans-saints-team-logo-2-150x150.png';
import newYorkGiants from '../assets/nfl/nfl-new-york-giants-team-logo-2-150x150.png';
import newYorkJets from '../assets/nfl/nfl-new-york-jets-team-logo-150x150.png';
import oaklandRaiders from '../assets/nfl/nfl-oakland-raiders-team-logo-150x150.png';
import philadelphiaEagles from '../assets/nfl/nfl-philadelphia-eagles-team-logo-2-150x150.png';
import pittsburghSteelers from '../assets/nfl/nfl-pittsburgh-steelers-team-logo-2-150x150.png';
import sanFrancisco49ers from '../assets/nfl/nfl-san-francisco-49ers-team-logo-2-150x150.png';
import seattleSeahawks from '../assets/nfl/nfl-seattle-seahawks-team-logo-2-150x150.png';
import tampaBayBuccaneers from '../assets/nfl/tampa-bay-buccaneers-2020-logo-150x150.png';
import tennesseeTitans from '../assets/nfl/nfl-tennessee-titans-team-logo-2-150x150.png';
import washingtonCommanders from '../assets/nfl/washington-commanders-logo-150x150.png';

// NHL Logos
import nhlLogo from '../assets/nhl/NHL.png';
import anaheimDucks from '../assets/nhl/Anaheim_Ducks.png';
import arizonaCoyotes from '../assets/nhl/Arizona_Coyotes.png';
import bostonBruins from '../assets/nhl/boston_bruins.png';
import buffaloSabres from '../assets/nhl/buffalo_sabres.png';
import calgaryFlames from '../assets/nhl/calgary_flames.png';
import carolinaHurricanes from '../assets/nhl/carolina_hurricanes.png';
import chicagoBlackhawks from '../assets/nhl/chicago_blackhawks.png';
import coloradoAvalanche from '../assets/nhl/colorado_avalanche.png';
import columbusBlueJackets from '../assets/nhl/columbus_blue_jackets.png';
import dallasStars from '../assets/nhl/dallas_stars.png';
import detroitRedWings from '../assets/nhl/detroit_red_wings.png';
import edmontonOilers from '../assets/nhl/edmonton_oilers.png';
import floridaPanthers from '../assets/nhl/florida_panthers.png';
import losAngelesKings from '../assets/nhl/los_angeles_kings.png';
import minnesotaWild from '../assets/nhl/minnesota_wild.png';
import montrealCanadiens from '../assets/nhl/montreal_canadiens.png';
import nashvillePredators from '../assets/nhl/nashville_predators.png';
import newJerseyDevils from '../assets/nhl/new_jersey_devils.png';
import newYorkIslanders from '../assets/nhl/new_york_islanders.png';
import newYorkRangers from '../assets/nhl/new_york_rangers.png';
import ottawaSenators from '../assets/nhl/ottawa_senators.png';
import philadelphiaFlyers from '../assets/nhl/philadelphia_flyers.png';
import pittsburghPenguins from '../assets/nhl/pittsburgh_penguins.png';
import sanJoseSharks from '../assets/nhl/san_jose_sharks.png';
import seattleKraken from '../assets/nhl/seattle_kraken.png';
import stLouisBlues from '../assets/nhl/st_louis_blues.png';
import tampaBayLightning from '../assets/nhl/tampa_bay_lightning.png';
import torontoMapleLeafs from '../assets/nhl/toronto_maple_leafs.png';
import vancouverCanucks from '../assets/nhl/vancouver_canucks.png';
import vegasGoldenKnights from '../assets/nhl/vegas_golden_knights.png';
import washingtonCapitals from '../assets/nhl/washington_capitals.png';
import winnipegJets from '../assets/nhl/winnipeg_jets.png';

import background from '../assets/sports_background_dark.png';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Welcome to Zeus Analytics!</h1>
        <p>The preferred choice for advanced sports analytics</p>
      </header>

      <section className="home-slider-container">
        <div className="home-slider">
          {/* NBA Section */}
          <div className="home-slider-item nba">
            <img src={nbaLogo} alt="NBA" className="league-logo" />
            <div className="team-logos">
              <img src={atlantaHawks} alt="Atlanta Hawks" />
              <img src={bostonCeltics} alt="Boston Celtics" />
              <img src={brooklynNets} alt="Brooklyn Nets" />
              <img src={charlotteHornets} alt="Charlotte Hornets" />
              <img src={chicagoBulls} alt="Chicago Bulls" />
              <img src={clevelandCavaliers} alt="Cleveland Cavaliers" />
              <img src={dallasMavericks} alt="Dallas Mavericks" />
              <img src={denverNuggets} alt="Denver Nuggets" />
              <img src={detroitPistons} alt="Detroit Pistons" />
              <img src={goldenStateWarriors} alt="Golden State Warriors" />
              <img src={houstonRockets} alt="Houston Rockets" />
              <img src={indianaPacers} alt="Indiana Pacers" />
              <img src={laClippers} alt="LA Clippers" />
              <img src={laLakers} alt="LA Lakers" />
              <img src={memphisGrizzlies} alt="Memphis Grizzlies" />
              <img src={miamiHeat} alt="Miami Heat" />
              <img src={milwaukeeBucks} alt="Milwaukee Bucks" />
              <img src={minnesotaTimberwolves} alt="Minnesota Timberwolves" />
              <img src={noPelicans} alt="New Orleans Pelicans" />
              <img src={newYorkKnicks} alt="New York Knicks" />
              <img src={oklahomaCityThunder} alt="Oklahoma City Thunder" />
              <img src={orlandoMagic} alt="Orlando Magic" />
              <img src={philadelphia76ers} alt="Philadelphia 76ers" />
              <img src={phoenixSuns} alt="Phoenix Suns" />
              <img src={portlandTrailblazers} alt="Portland Trailblazers" />
              <img src={sacramentoKings} alt="Sacramento Kings" />
              <img src={sanAntonioSpurs} alt="San Antonio Spurs" />
              <img src={torontoRaptors} alt="Toronto Raptors" />
              <img src={utahJazz} alt="Utah Jazz" />
              <img src={washingtonWizards} alt="Washington Wizards" />
            </div>
          </div>

          {/* MLB Section */}
          <div className="home-slider-item mlb">
            <img src={mlbLogo} alt="MLB" className="league-logo" />
            <div className="team-logos">
              <img src={arizonaDiamondbacks} alt="Arizona Diamondbacks" />
              <img src={atlantaBraves} alt="Atlanta Braves" />
              <img src={baltimoreOrioles} alt="Baltimore Orioles" />
              <img src={bostonRedSox} alt="Boston Red Sox" />
              <img src={chicagoCubs} alt="Chicago Cubs" />
              <img src={chicagoWhiteSox} alt="Chicago White Sox" />
              <img src={cincinnatiReds} alt="Cincinnati Reds" />
              <img src={clevelandGuardians} alt="Cleveland Guardians" />
              <img src={coloradoRockies} alt="Colorado Rockies" />
              <img src={detroitTigers} alt="Detroit Tigers" />
              <img src={houstonAstros} alt="Houston Astros" />
              <img src={kansasCityRoyals} alt="Kansas City Royals" />
              <img src={losAngelesAngels} alt="Los Angeles Angels" />
              <img src={losAngelesDodgers} alt="Los Angeles Dodgers" />
              <img src={miamiMarlins} alt="Miami Marlins" />
              <img src={milwaukeeBrewers} alt="Milwaukee Brewers" />
              <img src={minnesotaTwins} alt="Minnesota Twins" />
              <img src={newYorkMets} alt="New York Mets" />
              <img src={newYorkYankees} alt="New York Yankees" />
              <img src={oaklandAthletics} alt="Oakland Athletics" />
              <img src={philadelphiaPhillies} alt="Philadelphia Phillies" />
              <img src={pittsburghPirates} alt="Pittsburgh Pirates" />
              <img src={sanDiegoPadres} alt="San Diego Padres" />
              <img src={sanFranciscoGiants} alt="San Francisco Giants" />
              <img src={seattleMariners} alt="Seattle Mariners" />
              <img src={stLouisCardinals} alt="St. Louis Cardinals" />
              <img src={tampaBayRays} alt="Tampa Bay Rays" />
              <img src={texasRangers} alt="Texas Rangers" />
              <img src={torontoBlueJays} alt="Toronto Blue Jays" />
              <img src={washingtonNationals} alt="Washington Nationals" />
            </div>
          </div>

          {/* NFL Section */}
          <div className="home-slider-item nfl">
            <img src={nflLogo} alt="NFL" className="league-logo" />
            <div className="team-logos">
              <img src={arizonaCardinals} alt="Arizona Cardinals" />
              <img src={atlantaFalcons} alt="Atlanta Falcons" />
              <img src={baltimoreRavens} alt="Baltimore Ravens" />
              <img src={buffaloBills} alt="Buffalo Bills" />
              <img src={carolinaPanthers} alt="Carolina Panthers" />
              <img src={chicagoBearsNFL} alt="Chicago Bears" />
              <img src={cincinnatiBengals} alt="Cincinnati Bengals" />
              <img src={clevelandBrowns} alt="Cleveland Browns" />
              <img src={dallasCowboys} alt="Dallas Cowboys" />
              <img src={denverBroncos} alt="Denver Broncos" />
              <img src={detroitLions} alt="Detroit Lions" />
              <img src={greenBayPackers} alt="Green Bay Packers" />
              <img src={houstonTexans} alt="Houston Texans" />
              <img src={indianapolisColts} alt="Indianapolis Colts" />
              <img src={jacksonvilleJaguars} alt="Jacksonville Jaguars" />
              <img src={kansasCityChiefs} alt="Kansas City Chiefs" />
              <img src={losAngelesRams} alt="Los Angeles Rams" />
              <img src={losAngelesChargers} alt="Los Angeles Chargers" />
              <img src={miamiDolphins} alt="Miami Dolphins" />
              <img src={minnesotaVikings} alt="Minnesota Vikings" />
              <img src={newEnglandPatriots} alt="New England Patriots" />
              <img src={newOrleansSaints} alt="New Orleans Saints" />
              <img src={newYorkGiants} alt="New York Giants" />
              <img src={newYorkJets} alt="New York Jets" />
              <img src={oaklandRaiders} alt="Oakland Raiders" />
              <img src={philadelphiaEagles} alt="Philadelphia Eagles" />
              <img src={pittsburghSteelers} alt="Pittsburgh Steelers" />
              <img src={sanFrancisco49ers} alt="San Francisco 49ers" />
              <img src={seattleSeahawks} alt="Seattle Seahawks" />
              <img src={tampaBayBuccaneers} alt="Tampa Bay Buccaneers" />
              <img src={tennesseeTitans} alt="Tennessee Titans" />
              <img src={washingtonCommanders} alt="Washington Commanders" />
            </div>
          </div>

          {/* NHL Section */}
          <div className="home-slider-item nhl">
            <img src={nhlLogo} alt="NHL" className="league-logo" />
            <div className="team-logos">
              <img src={anaheimDucks} alt="Anaheim Ducks" />
              <img src={arizonaCoyotes} alt="Arizona Coyotes" />
              <img src={bostonBruins} alt="Boston Bruins" />
              <img src={buffaloSabres} alt="Buffalo Sabres" />
              <img src={calgaryFlames} alt="Calgary Flames" />
              <img src={carolinaHurricanes} alt="Carolina Hurricanes" />
              <img src={chicagoBlackhawks} alt="Chicago Blackhawks" />
              <img src={coloradoAvalanche} alt="Colorado Avalanche" />
              <img src={columbusBlueJackets} alt="Columbus Blue Jackets" />
              <img src={dallasStars} alt="Dallas Stars" />
              <img src={detroitRedWings} alt="Detroit Red Wings" />
              <img src={edmontonOilers} alt="Edmonton Oilers" />
              <img src={floridaPanthers} alt="Florida Panthers" />
              <img src={losAngelesKings} alt="Los Angeles Kings" />
              <img src={minnesotaWild} alt="Minnesota Wild" />
              <img src={montrealCanadiens} alt="Montreal Canadiens" />
              <img src={nashvillePredators} alt="Nashville Predators" />
              <img src={newJerseyDevils} alt="New Jersey Devils" />
              <img src={newYorkIslanders} alt="New York Islanders" />
              <img src={newYorkRangers} alt="New York Rangers" />
              <img src={ottawaSenators} alt="Ottawa Senators" />
              <img src={philadelphiaFlyers} alt="Philadelphia Flyers" />
              <img src={pittsburghPenguins} alt="Pittsburgh Penguins" />
              <img src={sanJoseSharks} alt="San Jose Sharks" />
              <img src={seattleKraken} alt="Seattle Kraken" />
              <img src={stLouisBlues} alt="St. Louis Blues" />
              <img src={tampaBayLightning} alt="Tampa Bay Lightning" />
              <img src={torontoMapleLeafs} alt="Toronto Maple Leafs" />
              <img src={vancouverCanucks} alt="Vancouver Canucks" />
              <img src={vegasGoldenKnights} alt="Vegas Golden Knights" />
              <img src={washingtonCapitals} alt="Washington Capitals" />
              <img src={winnipegJets} alt="Winnipeg Jets" />
            </div>
          </div>
        </div>
      </section>

      <section className="additional-content">
        <div className="content-block">
          <h2>About Us</h2>
          <p>
            Zeus Analytics is dedicated to providing valuable advanced analytical data. Our goal is to help sports bettors make informed decisions by delivering carefully curated data from popular cappers in the sports betting market.
          </p>
        </div>
        <div className="content-block">
          <h2>What We Offer</h2>
          <ul>
            <li>MLB Exit Velocity metrics and advanced batting data.</li>
            <li>MLB lineup pitches per plate appearance with league comparisons.</li>
            <li>Ballpark home run friendliness by batter handedness and time of day.</li>
            <li>Pitcher stats for the current day’s MLB games.</li>
            <li>1st Inning Stats for niche MLB markets.</li>
            <li>Comprehensive data coverage for NBA, NFL, MLB, and NHL.</li>
            <li>User-friendly interface and easy navigation.</li>
            <li>More leagues and data coming soon!</li>
          </ul>
        </div>
        <div className="content-block">
          <h2>Subscribe Now</h2>
          <p>
            Join our community and gain exclusive analytics and predictions. <a href="https://dubclub.win/r/Zeus_Analytics/">Subscribe today</a> and stay ahead of the game!
          </p>
        </div>
      </section>
    </div>
  );
};

export default Home;
