// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  FaBaseballBall,
  FaBasketballBall,
  FaFootballBall,
  FaHockeyPuck,
  FaTools,
} from 'react-icons/fa';
import './Navbar.css';

function Navbar({
  darkMode,
  toggleDarkMode,
  isAuthenticated,
  setIsAuthenticated,
  handleComingSoonClick,
}) {
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    // Close the mobile menu if the screen is resized above 768px
    const handleResize = () => {
      if (window.innerWidth > 768 && menuActive) {
        setMenuActive(false);
        closeAllSubmenus();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [menuActive]);

  const handleTitleClick = () => {
    if (window.innerWidth <= 768) {
      navigate('/');
      setMenuActive(false);
      closeAllSubmenus();
    }
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
    if (!menuActive) {
      // Menu just opened
      closeAllSubmenus();
    }
  };

  const closeAllSubmenus = () => {
    setOpenDropdown(null);
  };

  const handleLogout = () => {
    window.location.href = 'https://zeusanalytics.org/accounts/logout';
  };

  const handleLogin = () => {
    window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
  };

  const handleDropdownClick = (dropdownName) => {
    if (window.innerWidth <= 768) {
      // On mobile, toggle the clicked dropdown
      if (openDropdown === dropdownName) {
        setOpenDropdown(null);
      } else {
        setOpenDropdown(dropdownName);
      }
    }
  };

  return (
    <>
      <nav className="navbar-container">
        <div className="navbar-left">
          <Link to="/" className="navbar-item" onClick={handleTitleClick}>
            <img
              src="/zeusanalyticslogo2_BG_resized.png"
              alt="Home"
              className="navbar-home-icon"
            />
          </Link>
          <span className="navbar-z-mobile" onClick={handleTitleClick}>
            Zeus Analytics
          </span>
          <span className="navbar-title">Zeus Analytics</span>
        </div>

        <div className="navbar-hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className={`navbar-right ${menuActive ? 'active' : ''}`}>
          <div className="navbar-beta-label">BETA</div>

          {/* Dark Mode Toggle */}
          <div className="navbar-dark-mode-toggle">
            <label className="navbar-switch">
              <input
                type="checkbox"
                checked={darkMode}
                onChange={toggleDarkMode}
              />
              <span className="navbar-slider round"></span>
            </label>
            <span className="navbar-dark-mode-text">Dark Mode</span>
          </div>

          {/* MLB */}
          <div className="navbar-dropdown">
            <button
              className="navbar-dropbtn"
              onClick={() => handleDropdownClick('MLB')}
            >
              <FaBaseballBall className="navbar-icon" /> MLB
            </button>
            <div
              className={`navbar-dropdown-content ${
                openDropdown === 'MLB' ? 'show' : ''
              }`}
            >
              <Link
                to="/mlb/the-script"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                The Script
              </Link>
              <Link
                to="/mlb/exit-velocity-tracker"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Exit Velocity Tracker
              </Link>
              <Link
                to="/mlb/whos-hot"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Who's Hot
              </Link>
              <Link
                to="/mlb/starting-pitcher-stats"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Starting Pitcher Stats
              </Link>
              <Link
                to="/mlb/daily-lineup-stats"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Daily Lineup Stats
              </Link>
              <Link
                to="/mlb/inning-first-stats"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                1st Inning Stats
              </Link>
              <Link
                to="/mlb/park-factors"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Park Factors
              </Link>
              <Link
                to="/mlb/scores"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Scores
              </Link>
              <Link
                to="/mlb/definition"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Definitions
              </Link>
            </div>
          </div>

          {/* NBA */}
          <div className="navbar-dropdown">
            <button
              className="navbar-dropbtn"
              onClick={() => handleDropdownClick('NBA')}
            >
              <FaBasketballBall className="navbar-icon" /> NBA
            </button>
            <div
              className={`navbar-dropdown-content ${
                openDropdown === 'NBA' ? 'show' : ''
              }`}
            >
              <Link
                to="/nba/stat-tracker"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                NBA Stat Tracker
              </Link>
              <Link
                to="/nba/whos-hot"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                NBA Who's Hot
              </Link>
              <Link
                to="/nba/awards"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                NBA Daily Awards
              </Link>
              <Link
                to="/nba/three-pointer-hub"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                NBA Three Pointer Hub
              </Link>

              {/* ***** NEW LINK: NBA First Basket Predictions ***** */}
              <Link
                to="/nba/first-basket"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                NBA First Basket Predictions
              </Link>
            </div>
          </div>

          {/* NFL */}
          <div className="navbar-dropdown">
            <button
              className="navbar-dropbtn"
              onClick={() => handleDropdownClick('NFL')}
            >
              <FaFootballBall className="navbar-icon" /> NFL
            </button>
            <div
              className={`navbar-dropdown-content ${
                openDropdown === 'NFL' ? 'show' : ''
              }`}
            >
              <Link
                to="/nfl/top-td-predictions"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Top TD Predictions
              </Link>
              <Link
                to="/nfl/top-td-longshots"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Top TD Longshots
              </Link>
              <Link
                to="/nfl/defensive-team-stats"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Defensive Team Stats
              </Link>
              <Link
                to="/nfl/target-shares-and-defensive-coverages"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Target Share & Defensive Coverages
              </Link>
              <Link
                to="/nfl/nfl-hit-rates"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                NFL Hit Rates
              </Link>
            </div>
          </div>

          {/* NHL */}
          <div className="navbar-dropdown">
            <button
              className="navbar-dropbtn"
              onClick={() => handleDropdownClick('NHL')}
            >
              <FaHockeyPuck className="navbar-icon" /> NHL
            </button>
            <div
              className={`navbar-dropdown-content ${
                openDropdown === 'NHL' ? 'show' : ''
              }`}
            >
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleComingSoonClick(e);
                }}
              >
                COMING SOON...
              </Link>
            </div>
          </div>

          {/* Tools */}
          <div className="navbar-dropdown">
            <button
              className="navbar-dropbtn"
              onClick={() => handleDropdownClick('Tools')}
            >
              <FaTools className="navbar-icon" /> Tools
            </button>
            <div
              className={`navbar-dropdown-content ${
                openDropdown === 'Tools' ? 'show' : ''
              }`}
            >
              <Link
                to="/sportsbooks/arbitrage-calculator"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Arbitrage Calculator
              </Link>
              <Link
                to="/sportsbooks/odds-converter"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                Odds Converter
              </Link>
              <Link
                to="/faq"
                onClick={() => {
                  setMenuActive(false);
                  closeAllSubmenus();
                }}
              >
                FAQ
              </Link>
            </div>
          </div>

          {/* Authentication */}
          {!isAuthenticated && (
            <div className="navbar-dropdown">
              <button
                className="navbar-dropbtn navbar-login-button"
                onClick={() => {
                  handleLogin();
                }}
              >
                Login
              </button>
            </div>
          )}
          {isAuthenticated && (
            <div className="navbar-dropdown">
              <button
                className="navbar-dropbtn navbar-login-button"
                onClick={() => {
                  handleLogout();
                }}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </nav>

      {menuActive && (
        <div
          className="navbar-overlay"
          onClick={() => {
            setMenuActive(false);
            closeAllSubmenus();
          }}
        ></div>
      )}
    </>
  );
}

export default Navbar;
